import type { PropsWithChildren } from 'react';

import * as colors from '@src/support/colors';
import { SnackbarContainer } from '@src/components/snackbar-container';
import { TrustedHead } from '@src/components/trusted-head';
import TrustedWordmark from '@public/static/trusted_wordmark_white.svg';

import { DESKTOP_MIN_WIDTH } from '@src/constants/breakpoints';

type Props = {
  title: string;
};

export function AuthLayout(props: PropsWithChildren<Props>) {
  return (
    <div className="AuthLayout">
      <TrustedHead />
      {/* Do not change this page title. Prevents us from indexing well on public job search results. */}
      <SnackbarContainer />
      <div className="AuthLayoutBackground">
        <TrustedWordmark />
      </div>
      <div className="AuthLayoutForeground">
        <div className="AuthLayoutForegroundContent">
          <h1>{props.title}</h1>
          {props.children}
        </div>
      </div>
      <style jsx>{`
        .AuthLayout {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .AuthLayoutBackground {
          align-items: center;
          background-color: ${colors.black};
          display: flex;
          justify-content: center;
          padding: 2.5em;
        }

        .AuthLayoutBackground > :global(svg) {
          height: 2.5em;
        }

        .AuthLayoutForeground {
          background-color: ${colors.white};
          padding: 1.25em;
          display: flex;
        }

        .AuthLayoutForegroundContent {
          flex-grow: 1;
        }

        .AuthLayoutForegroundContent > h1 {
          font-size: 2em;
          font-weight: bold;
        }

        @media screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
          .AuthLayout {
            flex-direction: row-reverse;
          }

          .AuthLayoutBackground {
            flex-grow: 1;
            padding: 2.5em;
          }

          .AuthLayoutForeground {
            padding: 2.5em;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
          }

          .AuthLayoutForegroundContent {
            max-width: 31.25em;
          }
        }

        @media screen and (min-width: 64rem) {
          .AuthLayoutBackground > :global(svg) {
            height: 3.75em;
          }

          .AuthLayoutForeground {
            padding: 5em;
          }
        }
      `}</style>
    </div>
  );
}
